(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/results/assets/javascripts/services/fetch-results.js') >= 0) return;  svs.modules.push('/components/marketplace-data/results/assets/javascripts/services/fetch-results.js');
"use strict";


const {
  jupiter
} = svs.core;
const {
  normalizeTeamWithGames,
  postFilter,
  queryFilter
} = svs.components.marketplaceData.results.helpers;
const {
  normalize
} = svs.components.marketplaceData;
const PAGE_SIZE = 20;
const fetchResults = (filter, offset, callback, groupId, countParam) => {
  let query = queryFilter(filter);
  const count = countParam || PAGE_SIZE;
  query = offset ? query.concat("&offset=".concat(offset)) : query;
  query = query.concat("&count=".concat(count));
  let url;
  if (groupId) {
    url = "/marketplace/3/groups/".concat(groupId, "/containers?").concat(query);
  } else {
    url = "/marketplace/1/groups/search?".concat(query);
  }
  jupiter.get(url, response => {
    let result;
    let normalizedResult;
    let filteredGames;
    let totalCount;
    let resultCount;
    if (groupId) {
      normalizedResult = normalize.normalizeGames(response.currentUserCustomerId, ...(response.group ? response.group : response.containersWithDraw));
      filteredGames = normalizedResult;
      totalCount = response.totalHits;
      resultCount = response.containersWithDraw.length;
    } else {
      result = response.result || [];
      normalizedResult = result.map(r => normalizeTeamWithGames({
        payload: r
      }));
      filteredGames = postFilter(filter, normalizedResult);
      totalCount = response.totalCount;
      resultCount = result.length;
    }
    callback(undefined, {
      games: filteredGames,
      totalCount,
      offset,
      count: resultCount
    });
  }, error => {
    callback(error.responseJSON.error);
  });
};
setGlobal('svs.components.marketplaceData.results.services.fetchResults', fetchResults);

 })(window);